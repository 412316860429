/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  AppstoreOutlined,
  DashboardOutlined,
  GiftOutlined,
  LogoutOutlined,
  MenuOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';

export default function MobileMenu({ logout }) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem(
      <a href="/#/insights">Insights</a>,
      'insights',
      <AppstoreOutlined />,
      [
        getItem(
          <a href="/#/insights">Dashboard</a>,
          'dashboard',
          <DashboardOutlined />
        ),
        getItem(
          <a href="/#/leaderboards">Leaderboard</a>,
          'leaderboard',
          <TrophyOutlined />
        ),
      ]
    ),
    getItem(<a href="/#/rewards">Rewards</a>, 'rewards', <GiftOutlined />),
    getItem(<a onClick={logout}>Logout</a>, 'logout', <LogoutOutlined />),
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        ghost
        style={{
          marginLeft: 12,
          border: 'none',
          boxShadow: 'none',
          fontSize: '20px',
          lineHeight: '22px',
        }}
      >
        <MenuOutlined />
      </Button>
      <Drawer
        title="Menu"
        placement="top"
        onClose={onClose}
        open={open}
        width={'100%'}
        height={'450px'}
      >
        <Menu
          mode="inline"
          className="Mobile_menu"
          defaultOpenKeys={['insights', 'dashboard']}
          items={items}
        />
      </Drawer>
    </>
  );
}
